@import url("https://fonts.googleapis.com/css2?family=Cairo&family=Roboto&display=swap");

html {
  font-family: "Cairo", sans-serif;
}
.rdrDateDisplayWrapper {
  display: none;
}
.rdrInputRanges {
  display: none;
  visibility: hidden;
}

.required-label::after {
  content: "*";
  color: red;
  margin-left: 4px;
  font-size: 0.875rem; /* Adjust size if needed */
}

/* .cabinet_is_active {
  background-color: rgb(250, 122, 122);
} */

/* Maintain red background color on hover */
.active-row:hover {
  background-color: rgb(250, 122, 122) !important;
}

.warning-sign {
  border: 2px solid #ffa500;
  background-color: #d3540034;
}

.cabinet_is_active {
  border: 2px solid #ff0000;
  background-color: #d3000034;
}
